import { Collection, Account } from '@/models';

import AccountTypeEnum from '@/enums/account/type';
import AccountStatusEnum from '@/enums/account/status';

import i18n from '@/lib/i18n';

export class AccountCollection extends Collection {
    static type = 'AccountCollection';
    static endpoint = Account.api.index;
    static model = Account;

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                filterable: true,
                width: '20rem'
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('type', i18n.t('type'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: AccountTypeEnum,
                enumA11yKey: 'enums.account_type'
            }),
            this.createHeader('type_name', i18n.t('fund_or_custodian'), {
                width: '15rem',
                transform: data => {
                    try {
                        if (
                            (data.item.type === AccountTypeEnum.PLATFORM || data.item.type === AccountTypeEnum.ISA) &&
                            data.item.custodian
                        ) {
                            return data.item.custody_accounts.map(ca => ca.custodian.name).join(', ');
                        }
                        if (
                            (data.item.type === AccountTypeEnum.FUND || data.item.type === AccountTypeEnum.ISAFUND) &&
                            data.item.fund
                        ) {
                            return data.item.fund.name;
                        }
                    } catch (error) {
                        //
                    }
                    return null;
                }
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: AccountStatusEnum,
                enumA11yKey: 'enums.account_status'
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                width: '15rem'
            }),
            this.createHeader('currency', i18n.t('currency'), {
                filterable: true,
                width: '5rem'
            }),
            this.createHeader('reconciled_balance', i18n.t('reconciled_balance'), {
                format: 'money',
                width: '12rem',
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('reconciled_balance_updated_at', i18n.t('reconciled_balance_updated_at'), {
                filterable: true,
                format: 'datetime',
                width: '12rem'
            }),
            this.createHeader('adviser_name', i18n.t('adviser'), {
                path: 'adviser.name',
                width: '15rem'
            }),
            this.createHeader('investor_name', i18n.t('investor'), {
                path: 'investor.name',
                width: '15rem'
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }

    get default_headers() {
        return ['type', 'type_name', 'status', 'name', 'currency', 'reconciled_balance'];
    }
}

export default AccountCollection;
