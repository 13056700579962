import getFieldType from '@/lib/helpers/getFieldType';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';

const createHeader = (key, name, options = null) => {
    let defaultAlignment = 'left';
    let defaultFilterable = false;
    let defaultSortable = false;
    let defaultClassList = [];
    let defaultTransform = null;
    let defaultFallback = '-';

    if (has(options, 'format')) {
        if (['number', 'money', 'percentage'].includes(options.format)) {
            defaultAlignment = 'right';
            defaultSortable = true;
            defaultFilterable = true;
        } else if (['boolean'].includes(options.format)) {
            defaultAlignment = 'center';
            defaultSortable = false;
            defaultFilterable = true;
        } else if (['datetime', 'date', 'enum'].includes(options.format)) {
            defaultAlignment = 'left';
            defaultSortable = true;
            defaultFilterable = true;
        } else if (['control'].includes(options.format)) {
            defaultAlignment = 'right';
            defaultSortable = false;
            defaultFilterable = false;
            defaultClassList = ['no-wrap'];
            defaultTransform = data => {
                data.item.loading = false;
                return data;
            };
        }
    }

    options = {
        column: key,
        path: null, // Path to value inside the table data object
        align: defaultAlignment, // Alignment: left | center | right
        format: 'string', // string | number | money | date | datetime | percentage | boolean | wysiwyg | enum
        display: null, // string | number | money | date | datetime | percentage | boolean | wysiwyg | enum
        formatOptions: null,
        filterable: defaultFilterable, // boolean | object
        sortable: defaultSortable,
        classList: defaultClassList, // Assign classes to the table cell
        transform: defaultTransform, // Transform the column value before display
        to: null,
        append: null,
        prepend: null,
        width: '100%', // Width of the column when using `table-layout: fixed`
        minWidth: null, // Min-width of the column when using `table-layout: fixed`
        enum: {},
        enumA11yKey: null,
        enumTypes: {},
        active: true,
        fallback: defaultFallback,
        tooltip: null,
        persistent: false,
        multiple: false,
        component: null,
        editable: false,
        ...options,
        //
        componentOptions: {
            label: options.componentOptions && options.componentOptions.label,
            setItemValueCallback:
                options.componentOptions && options.componentOptions.setItemValueCallback
                    ? options.componentOptions.setItemValueCallback
                    : () => {},
            disabled:
                options.componentOptions && options.componentOptions.disabled
                    ? options.componentOptions.disabled
                    : () => false,
            hideDetails:
                options.componentOptions && options.componentOptions.hideDetails
                    ? options.componentOptions.hideDetails
                    : () => true,
            hideLabel:
                options.componentOptions && options.componentOptions.hideLabel
                    ? options.componentOptions.hideLabel
                    : () => true,
            placeholder:
                options.componentOptions && options.componentOptions.placeholder
                    ? options.componentOptions.placeholder
                    : () => null
        }
    };

    return {
        column: options.column,
        text: name || '',
        value: key || null,
        path: options.path,
        align: options.align,
        display: options.display || options.format || 'string',
        format: options.format,
        formatOptions: options.formatOptions,
        fieldType: getFieldType(options.format),
        filterable: options.filterable,
        sortable: !!options.sortable,
        sortKey: typeof options.sortable === 'string' ? options.sortable : options.column,
        classList: isArray(options.classList) ? options.classList.join(' ') : options.classList,
        transform: isFunction(options.transform) ? options.transform : null,
        to: isFunction(options.to) ? options.to : null,
        append: options.append,
        prepend: options.prepend,
        width: options.width,
        minWidth: options.minWidth,
        enum: options.enum,
        enumA11yKey: options.enumA11yKey,
        enumTypes: options.enumTypes,
        active: options.active,
        fallback: options.fallback,
        tooltip: options.tooltip,
        persistent: options.persistent,
        multiple: options.multiple,
        component: options.component,
        componentOptions: options.componentOptions,
        editable: options.editable
    };
};

export default createHeader;
