import { Collection, Transaction } from '@/models';

import TransactionTypeEnum from '@/enums/transaction/type';
import AccountTypeEnum from '@/enums/account/type';

import i18n from '@/lib/i18n';

export class SystemTransactionsCollection extends Collection {
    static type = 'SystemTransactionsCollection';
    static endpoint = Transaction.api.index;
    static model = Transaction;

    get default_headers() {
        return ['locked', 'description', 'value_at', 'gi_ref', 'investor_id', 'adviser_id', 'amount'];
    }

    get headers() {
        return [
            this.createHeader('control', '', {
                format: 'control',
                width: '5rem',
                persistent: true
            }),
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('description', i18n.t('transaction'), {
                width: '18rem',
                filterable: true
            }),
            this.createHeader('value_at', i18n.t('date'), {
                format: 'datetime',
                width: '8rem',
                formatOptions: {
                    showTime: true
                }
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                width: '8rem',
                filterable: true
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                width: '10rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                },
                transform: data => data.item?.account?.investor?.name
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                width: '10rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                },
                transform: data => data.item?.account?.adviser?.name
            }),
            this.createHeader('type', i18n.t('type'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: TransactionTypeEnum,
                enumA11yKey: 'enums.transaction_type'
            }),
            this.createHeader('account_type', i18n.t('account_type'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: AccountTypeEnum,
                enumA11yKey: 'enums.account_type'
            }),
            this.createHeader('application_at', i18n.t('application_date'), {
                format: 'datetime',
                width: '11rem'
            }),
            this.createHeader('reconciled', i18n.t('reconciled'), {
                format: 'boolean',
                width: '7rem'
            }),
            this.createHeader('locked', i18n.t('locked'), {
                format: 'boolean',
                width: '7rem'
            }),
            this.createHeader('amount', i18n.t('amount'), {
                format: 'money',
                width: '9rem',
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('record_at', i18n.t('record_at'), {
                format: 'datetime',
                width: '12rem'
            }),
            this.createHeader('accrue_at', i18n.t('accrue_at'), {
                format: 'datetime',
                width: '12rem'
            }),
            this.createHeader('source', i18n.t('source'), {
                width: '12rem'
            }),
            this.createHeader('external_client_ref', i18n.t('external_client_ref'), {
                width: '12rem'
            }),
            this.createHeader('processor_client_code', i18n.t('processor_client_code'), {
                width: '12rem'
            }),
            this.createHeader('wrapper', i18n.t('wrapper'), {
                width: '12rem'
            }),
            this.createHeader('ri', i18n.t('ri'), {
                width: '12rem'
            }),
            this.createHeader('ifa', i18n.t('ifa'), {
                width: '12rem'
            }),
            this.createHeader('transaction_date', i18n.t('transaction_date'), {
                format: 'date',
                width: '12rem'
            }),
            this.createHeader('p1_client_code', i18n.t('p1_client_code'), {
                width: '12rem'
            }),
            this.createHeader('legacy_user_id', i18n.t('legacy_user_id'), {
                width: '12rem'
            }),
            this.createHeader('received_on', i18n.t('received_on'), {
                format: 'date',
                width: '12rem'
            }),
            this.createHeader('reconciled_at', i18n.t('reconciled_at'), {
                format: 'datetime',
                width: '12rem'
            })
        ];
    }
}

export default SystemTransactionsCollection;
