import { Collection, Transaction, Investor, Account } from '@/models';

import TransactionTypeEnum from '@/enums/transaction/type';
// import AccountTypeEnum from '@/enums/account/type';

import i18n from '@/lib/i18n';

export class TransactionCollection extends Collection {
    static type = 'TransactionCollection';
    static endpoint = Transaction.api.index;
    static model = Transaction;

    static fields() {
        return {
            ...super.fields(),

            investor_id: this.string(null).nullable(),
            investor: this.belongsTo(Investor, 'investor_id'),

            account_id: this.string(null).nullable(),
            account: this.belongsTo(Account, 'account_id')
        };
    }

    get headers() {
        return [
            this.createHeader('pending_allotment', i18n.t('pending_allotment'), {
                filterable: {
                    operators: this.getOperators(['eq'])
                },
                format: 'boolean',
                active: false
            }),
            this.createHeader('tax_year', i18n.t('tax_year'), {
                active: false,
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/TaxYear')
                }
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                classList: ['no-wrap'],
                filterable: true,
                width: '12rem'
            }),
            this.createHeader('account_name', i18n.t('account'), {
                path: 'account.name',
                filterable: true,
                width: '14rem'
            }),
            this.createHeader('fund_manager_name', i18n.t('fund_manager'), {
                path: 'fund_investment.fund.fund_manager.name',
                filterable: true,
                width: '18rem'
            }),
            this.createHeader('on_platform', i18n.t('on_platform'), {
                filterable: true,
                format: 'boolean',
                width: '9rem'
            }),
            this.createHeader('in_custody', i18n.t('custodied'), {
                filterable: true,
                format: 'boolean',
                width: '9rem'
            }),
            this.createHeader('application_at', i18n.t('application_date'), {
                format: 'datetime',
                width: '12rem'
            }),
            this.createHeader('record_at', i18n.t('record_at'), {
                filterable: true,
                format: 'datetime',
                width: '12rem'
            }),
            this.createHeader('transaction_date', i18n.t('transaction_date'), {
                filterable: true,
                format: 'date',
                width: '12rem'
            }),
            this.createHeader('value_at', i18n.t('value_at'), {
                filterable: true,
                format: 'datetime',
                width: '12rem'
            }),
            this.createHeader('type', i18n.t('type'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: TransactionTypeEnum,
                enumA11yKey: 'enums.transaction_type'
            }),
            this.createHeader('description', i18n.t('description'), {
                filterable: true,
                width: '20rem'
            }),
            this.createHeader('share_quantity', i18n.t('share_quantity'), {
                filterable: true,
                format: 'number',
                width: '8rem',
                fallback: data => {
                    if (data.item.pending_allotment) {
                        return i18n.t('pending');
                    }

                    return '-';
                }
            }),
            this.createHeader('share_price', i18n.t('share_price'), {
                filterable: true,
                format: 'money',
                formatOptions: super.money_format_options,
                width: '12rem',
                fallback: data => {
                    if (data.item.pending_allotment) {
                        return i18n.t('pending');
                    }

                    return '-';
                }
            }),
            this.createHeader('amount', i18n.t('amount'), {
                filterable: true,
                format: 'money',
                width: '12rem',
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                },
                classList: data => {
                    let classList = [];

                    if ('amount' in data.item && 'amount' in data.item.amount) {
                        if (data.item.amount.amount < 0) {
                            classList.push('error--text');
                            classList.push('text--darken-1');
                        }
                    }

                    return classList.join(' ');
                }
            })
        ];
    }

    get default_headers() {
        return [
            'gi_ref',
            'account_name',
            // 'application_at',
            'transaction_date',
            'value_date',
            'type',
            'description',
            'share_quantity',
            'share_issue_price',
            'amount'
        ];
    }

    get preset_filters() {
        let filters = {};

        if (this.investor_id) {
            filters.investor_id = this.filterOperator('is') + ':' + this.investor_id;
        }

        if (this.account_id) {
            filters.account_id = this.filterOperator('is') + ':' + this.account_id;
        }

        if (this.tax_year) {
            filters.tax_year = this.tax_year;
        }

        return filters;
    }
}

export default TransactionCollection;
