<template>
    <transition appear name="fade" mode="out-in">
        <v-container fluid class="app-container-height">
            <v-layout align-center justify-space-between class="mb-4">
                <v-flex>
                    <app-tabs :items="tabs" />
                </v-flex>
                <v-flex shrink class="pl-4 d-flex align-center">
                    <app-query-selector
                        v-if="isAdviser()"
                        required
                        query-key="investor_id"
                        :label="$t('switch_investor')"
                        :collection="new InvestorCollection()"
                        style="min-width: 12rem; width: 12rem"
                        item-tooltip="email"
                        @load="onInvestorChange"
                        @change="onInvestorChange"
                    />
                    <app-toggle-nav-totals class="ml-4" />
                    <app-toggle-consolidated-view class="ml-4" :text="$t('combine_portfolios')" />
                </v-flex>
            </v-layout>
            <div class="app-container-wrapper">
                <transition appear name="fade" mode="out-in">
                    <router-view v-if="investor.id" :key="$route.fullPath" :investor="investor" />
                </transition>
            </div>
        </v-container>
    </transition>
</template>

<script>
import table from '@/mixins/table';
import { InvestorCollection, Investor } from '@/models';

export const tabs = [
    'summary',
    'single_company',
    'eis_seis',
    'vct',
    'iht',
    'pmi'
    // 'esg'
];

export default {
    name: 'PortfolioDetail',
    components: {
        AppTabs: () =>
            import(
                /* webpackChunkName: "core" */
                '@/components/AppTabs'
            )
    },
    mixins: [table],
    data: () => ({
        InvestorCollection,
        Investor,
        investor: new Investor(),
        tabs: [],
        investor_id: null
    }),
    computed: {
        investorId() {
            if (this.isInvestor()) {
                return this.getOrganisationId();
            }

            return this.investor_id;
        }
    },
    methods: {
        onInvestorChange(investorId) {
            this.investor_id = investorId;
            this.setup();
        },
        async setup() {
            try {
                if (!this.investorId) {
                    return;
                }

                await Investor.api.get(this.investorId);
                this.investor = Investor.find(this.investorId);

                const query = this.isInvestor() ? null : { investor_id: this.investorId };

                this.tabs = tabs.map(item => ({
                    id: this._.snakeCase(item),
                    name: this.$t('enums.portfolio_detail_tabs.' + this._.snakeCase(item)),
                    badge: null,
                    to: {
                        name: 'PortfolioDetail' + this._.upperFirst(this._.camelCase(item)),
                        query
                    }
                }));

                this.setBreadcrumbs([
                    {
                        heading: this.$route.meta.heading
                    }
                ]);
            } catch (error) {
                if (error?.code === 403) {
                    this.$router.push({ query: { investor_id: null } });
                } else {
                    this.handleError(error, () => this.setup());
                }
            }
        }
    }
};
</script>
