/**
 * Parse validation errors from the API
 * @param {Object} errors
 */
const parseValidationErrors = (errors, removeFirstLoc = true) => {
    if (!errors) {
        return [];
    }

    let parsed = errors.map(error => {
        let parts = [];

        if (removeFirstLoc) {
            parts = error.loc ? error.loc.filter((p, i) => i !== 0) : [];
        } else {
            parts = error.loc ? error.loc : [];
        }

        let name = null;
        const field = parts.join('.');
        const message = error.msg;
        const type = error.type;

        try {
            name =
                error.msg +
                ' for ' +
                parts
                    .reverse()
                    .map(part => {
                        if (!isNaN(parseInt(part))) {
                            return `on row ${parseInt(part) + 1} of`;
                        }

                        return part.replace('_', ' ');
                    })
                    .join(' ');
        } catch (error) {
            name = message + ' for ' + field;
        }

        name = name.charAt(0).toUpperCase() + name.slice(1) + '.';

        return { name, field, message, type };
    });

    return parsed;
};

export default parseValidationErrors;
