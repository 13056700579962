import { Collection, Account } from '@/models';

import AccountTypeEnum from '@/enums/account/type';
import AccountStatusEnum from '@/enums/account/status';

import i18n from '@/lib/i18n';

export class SystemAccountsCollection extends Collection {
    static type = 'SystemAccountsCollection';
    static endpoint = Account.api.index;
    static model = Account;

    get default_headers() {
        return ['name', 'gi_ref', 'type', 'type_name', 'status', 'adviser_id', 'investor_id'];
    }

    get headers() {
        return [
            this.createHeader('id', i18n.t('id'), {
                classList: ['no-wrap'],
                width: '20rem',
                filterable: {
                    operators: this.getOperators(['eq'])
                }
            }),
            this.createHeader('name', i18n.t('name'), {
                filterable: true,
                width: '15rem'
            }),
            this.createHeader('gi_ref', i18n.t('gi_ref'), {
                filterable: true,
                width: '9rem'
            }),
            this.createHeader('type', i18n.t('type'), {
                filterable: true,
                width: '10rem',
                format: 'enum',
                enum: AccountTypeEnum,
                enumA11yKey: 'enums.account_type'
            }),
            this.createHeader('type_name', i18n.t('fund_or_custodian'), {
                width: '15rem',
                transform: data => {
                    try {
                        if (
                            (data.item.type === AccountTypeEnum.PLATFORM || data.item.type === AccountTypeEnum.ISA) &&
                            data.item.custodian
                        ) {
                            return data.item.custody_accounts.map(ca => ca.custodian.name).join(', ');
                        }
                        if (
                            (data.item.type === AccountTypeEnum.FUND || data.item.type === AccountTypeEnum.ISAFUND) &&
                            data.item.fund
                        ) {
                            return data.item.fund.name;
                        }
                    } catch (error) {
                        //
                    }
                    return null;
                }
            }),
            this.createHeader('status', i18n.t('status'), {
                filterable: true,
                width: '8rem',
                format: 'enum',
                enum: AccountStatusEnum,
                enumA11yKey: 'enums.account_status',
                enumTypes: {
                    [AccountStatusEnum.ACTIVE]: 'success',
                    [AccountStatusEnum.DORMANT]: 'warning',
                    [AccountStatusEnum.SUSPENDED]: 'error',
                    [AccountStatusEnum.CLOSED]: 'error'
                }
            }),
            this.createHeader('currency', i18n.t('currency'), {
                width: '5rem'
            }),
            this.createHeader('reconciled_balance', i18n.t('reconciled_balance'), {
                format: 'money',
                width: '12rem',
                formatOptions: {
                    decimals: 2,
                    abbreviate: false
                }
            }),
            this.createHeader('reconciled_balance_updated_at', i18n.t('reconciled_balance_updated_at'), {
                filterable: true,
                format: 'datetime',
                width: '12rem'
            }),
            this.createHeader('adviser_id', i18n.t('adviser'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Adviser')
                },
                transform: data => data.item?.adviser?.name
            }),
            this.createHeader('investor_id', i18n.t('investor'), {
                width: '14rem',
                filterable: {
                    operators: this.getOperators(['eq']),
                    component: () => import('@/components/fields/Investor')
                },
                transform: data => data.item?.investor?.name
            }),
            this.createHeader('control', '', {
                format: 'control',
                width: '7rem',
                persistent: true
            })
        ];
    }
}

export default SystemAccountsCollection;
