import permissions from '@/enums/auth/permission';
import i18n from '@/lib/i18n';

export default [
    {
        path: '/system/data-entry',
        name: 'SystemDataEntry',
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "data-entry" */
                    '@/views/SystemDataEntry.vue'
                ),
            sidebar: () =>
                import(
                    /* webpackChunkName: "data-entry" */
                    '@/sidebars/SystemDataEntrySidebar.vue'
                )
        },
        meta: {
            title: i18n.t('data_entry'),
            heading: i18n.t('data_entry'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        },
        children: [
            {
                path: 'activity',
                name: 'SystemDataEntryActivity',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryActivity.vue'
                    ),
                meta: {
                    title: i18n.t('activity'),
                    heading: i18n.t('activity'),
                    permissions: [permissions.ANY_USER_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'activity/create/:id?',
                name: 'SystemDataEntryActivityCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryActivityCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('activity'),
                    heading: i18n.t('create') + ' ' + i18n.t('activity'),
                    permissions: [permissions.ANY_USER_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'activity/edit/:id',
                name: 'SystemDataEntryActivityEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryActivityEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('activity'),
                    heading: i18n.t('edit') + ' ' + i18n.t('activity'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'users',
                name: 'SystemDataEntryUsers',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryUsers.vue'
                    ),
                meta: {
                    title: i18n.t('users'),
                    heading: i18n.t('users'),
                    permissions: [permissions.ANY_USER_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'users/create/:id?',
                name: 'SystemDataEntryUsersCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryUsersCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('user'),
                    heading: i18n.t('create') + ' ' + i18n.t('user'),
                    permissions: [permissions.ANY_USER_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'users/edit/:id',
                name: 'SystemDataEntryUsersEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryUsersEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('user'),
                    heading: i18n.t('edit') + ' ' + i18n.t('user'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'advisers',
                name: 'SystemDataEntryAdvisers',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryAdvisers.vue'
                    ),
                meta: {
                    title: i18n.t('advisers'),
                    heading: i18n.t('advisers'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'advisers/create/:id?',
                name: 'SystemDataEntryAdvisersCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryAdvisersCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('adviser'),
                    heading: i18n.t('create') + ' ' + i18n.t('adviser'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'advisers/edit/:id',
                name: 'SystemDataEntryAdvisersEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryAdvisersEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('adviser'),
                    heading: i18n.t('edit') + ' ' + i18n.t('adviser'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'companies',
                name: 'SystemDataEntryCompanies',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryCompanies.vue'
                    ),
                meta: {
                    title: i18n.t('companies'),
                    heading: i18n.t('companies'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'companies/create/:id?',
                name: 'SystemDataEntryCompaniesCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryCompaniesCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('company'),
                    heading: i18n.t('create') + ' ' + i18n.t('company'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'companies/edit/:id',
                name: 'SystemDataEntryCompaniesEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryCompaniesEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('company'),
                    heading: i18n.t('edit') + ' ' + i18n.t('company'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'fees',
                name: 'SystemDataEntryFees',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryFees.vue'
                    ),
                meta: {
                    title: i18n.t('fee_plans'),
                    heading: i18n.t('fee_plans'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'fees/create/:id?',
                name: 'SystemDataEntryFeesCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryFeesCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('fee') + ' ' + i18n.t('plan'),
                    heading: i18n.t('create') + ' ' + i18n.t('fee') + ' ' + i18n.t('plan'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'fees/edit/:id',
                name: 'SystemDataEntryFeesEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryFeesEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('fee') + ' ' + i18n.t('plan'),
                    heading: i18n.t('edit') + ' ' + i18n.t('fee') + ' ' + i18n.t('plan'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'fund-managers',
                name: 'SystemDataEntryFundManagers',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryFundManagers.vue'
                    ),
                meta: {
                    title: i18n.t('fund_managers'),
                    heading: i18n.t('fund_managers'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'fund-managers/create/:id?',
                name: 'SystemDataEntryFundManagersCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryFundManagersCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('fund_manager'),
                    heading: i18n.t('create') + ' ' + i18n.t('fund_manager'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'fund-managers/edit/:id',
                name: 'SystemDataEntryFundManagersEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryFundManagersEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('fund_manager'),
                    heading: i18n.t('edit') + ' ' + i18n.t('fund_manager'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'funds',
                name: 'SystemDataEntryFunds',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryFunds.vue'
                    ),
                meta: {
                    title: i18n.t('funds'),
                    heading: i18n.t('funds'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'funds/create/:id?',
                name: 'SystemDataEntryFundsCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryFundsCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('fund'),
                    heading: i18n.t('create') + ' ' + i18n.t('fund'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'funds/edit/:id',
                name: 'SystemDataEntryFundsEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryFundsEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('fund'),
                    heading: i18n.t('edit') + ' ' + i18n.t('fund'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'holdings',
                name: 'SystemDataEntryHoldings',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryHoldings.vue'
                    ),
                meta: {
                    title: i18n.t('holdings'),
                    heading: i18n.t('holdings'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'holdings/edit/:id',
                name: 'SystemDataEntryHoldingsEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryHoldingsEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('holding'),
                    heading: i18n.t('edit') + ' ' + i18n.t('holding'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'investors',
                name: 'SystemDataEntryInvestors',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryInvestors.vue'
                    ),
                meta: {
                    title: i18n.t('investors'),
                    heading: i18n.t('investors'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'investors/create/:id?',
                name: 'SystemDataEntryInvestorsCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryInvestorsCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('investor'),
                    heading: i18n.t('create') + ' ' + i18n.t('investor'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'investors/edit/:id',
                name: 'SystemDataEntryInvestorsEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryInvestorsEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('investor'),
                    heading: i18n.t('edit') + ' ' + i18n.t('investor'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'offers',
                name: 'SystemDataEntryOffers',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryOffers.vue'
                    ),
                meta: {
                    title: i18n.t('offers'),
                    heading: i18n.t('offers'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'offers/create',
                name: 'SystemDataEntryOffersCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryOffersCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('offer'),
                    heading: i18n.t('create') + ' ' + i18n.t('offer'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'offers/edit/:id',
                name: 'SystemDataEntryOffersEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryOffersEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('offer'),
                    heading: i18n.t('edit') + ' ' + i18n.t('offer'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'products',
                name: 'SystemDataEntryProducts',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryProducts.vue'
                    ),
                meta: {
                    title: i18n.t('products'),
                    heading: i18n.t('products'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'products/create/:id?',
                name: 'SystemDataEntryProductsCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryProductsCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('product'),
                    heading: i18n.t('create') + ' ' + i18n.t('product'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'products/edit/:id',
                name: 'SystemDataEntryProductsEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryProductsEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('product'),
                    heading: i18n.t('edit') + ' ' + i18n.t('product'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'providers',
                name: 'SystemDataEntryProviders',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryProviders.vue'
                    ),
                meta: {
                    title: i18n.t('providers'),
                    heading: i18n.t('providers'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'providers/create/:id?',
                name: 'SystemDataEntryProvidersCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryProvidersCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('provider'),
                    heading: i18n.t('create') + ' ' + i18n.t('provider'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'providers/edit/:id',
                name: 'SystemDataEntryProvidersEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryProvidersEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('provider'),
                    heading: i18n.t('edit') + ' ' + i18n.t('provider'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'receiving-agents',
                name: 'SystemDataEntryReceivingAgents',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryReceivingAgents.vue'
                    ),
                meta: {
                    title: i18n.t('receiving_agent'),
                    heading: i18n.t('receiving_agent'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'receiving-agents/create/:id?',
                name: 'SystemDataEntryReceivingAgentsCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryReceivingAgentsCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('receiving_agent'),
                    heading: i18n.t('create') + ' ' + i18n.t('receiving_agent'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'receiving-agents/edit/:id',
                name: 'SystemDataEntryReceivingAgentsEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryReceivingAgentsEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('receiving_agent'),
                    heading: i18n.t('edit') + ' ' + i18n.t('receiving_agent'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'registrars',
                name: 'SystemDataEntryRegistrars',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryRegistrars.vue'
                    ),
                meta: {
                    title: i18n.t('registrars'),
                    heading: i18n.t('registrars'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'registrars/create/:id?',
                name: 'SystemDataEntryRegistrarsCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryRegistrarsCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('registrar'),
                    heading: i18n.t('create') + ' ' + i18n.t('registrar'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'registrars/edit/:id',
                name: 'SystemDataEntryRegistrarsEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryRegistrarsEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('registrar'),
                    heading: i18n.t('edit') + ' ' + i18n.t('registrar'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'accounts',
                name: 'SystemDataEntryAccounts',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryAccounts.vue'
                    ),
                meta: {
                    title: i18n.t('accounts'),
                    heading: i18n.t('accounts'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'accounts/create/:id?',
                name: 'SystemDataEntryAccountsCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryAccountsCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('account'),
                    heading: i18n.t('create') + ' ' + i18n.t('account'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'accounts/edit/:id',
                name: 'SystemDataEntryAccountsEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryAccountsEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('account'),
                    heading: i18n.t('edit') + ' ' + i18n.t('account'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'transactions',
                name: 'SystemDataEntryTransactions',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryTransactions.vue'
                    ),
                meta: {
                    title: i18n.t('transactions'),
                    heading: i18n.t('transactions'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'transactions/create/:id?',
                name: 'SystemDataEntryTransactionsCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryTransactionsCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('transaction'),
                    heading: i18n.t('create') + ' ' + i18n.t('transaction'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'transactions/edit/:id',
                name: 'SystemDataEntryTransactionsEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryTransactionsEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('transaction'),
                    heading: i18n.t('edit') + ' ' + i18n.t('transaction'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'custodians',
                name: 'SystemDataEntryCustodians',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryCustodians.vue'
                    ),
                meta: {
                    title: i18n.t('custodians'),
                    heading: i18n.t('custodians'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'custodians/create/:id?',
                name: 'SystemDataEntryCustodiansCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryCustodiansCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('custodian'),
                    heading: i18n.t('create') + ' ' + i18n.t('custodian'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'custodians/edit/:id',
                name: 'SystemDataEntryCustodiansEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryCustodiansEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('custodian'),
                    heading: i18n.t('edit') + ' ' + i18n.t('custodian'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'nominees',
                name: 'SystemDataEntryNominees',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryNominees.vue'
                    ),
                meta: {
                    title: i18n.t('nominees'),
                    heading: i18n.t('nominees'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'nominees/create/:id?',
                name: 'SystemDataEntryNomineesCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryNomineesCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('nominee'),
                    heading: i18n.t('create') + ' ' + i18n.t('nominee'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'nominees/edit/:id',
                name: 'SystemDataEntryNomineesEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryNomineesEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('nominee'),
                    heading: i18n.t('edit') + ' ' + i18n.t('nominee'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'tranche',
                name: 'SystemDataEntryTranches',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryTranches.vue'
                    ),
                meta: {
                    title: i18n.t('tranches'),
                    heading: i18n.t('tranches'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'tranche/create/:id?',
                name: 'SystemDataEntryTranchesCreate',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryTranchesCreate.vue'
                    ),
                meta: {
                    title: i18n.t('create') + ' ' + i18n.t('tranche'),
                    heading: i18n.t('create') + ' ' + i18n.t('tranche'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'tranche/edit/:id',
                name: 'SystemDataEntryTranchesEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryTranchesEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('tranche'),
                    heading: i18n.t('edit') + ' ' + i18n.t('tranche'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'registrations',
                name: 'SystemDataEntryRegistrations',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryRegistrations.vue'
                    ),
                meta: {
                    title: i18n.t('registrations'),
                    heading: i18n.t('registrations'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'registrations/edit/:id',
                name: 'SystemDataEntryRegistrationsEdit',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryRegistrationsEdit.vue'
                    ),
                meta: {
                    title: i18n.t('edit') + ' ' + i18n.t('registration'),
                    heading: i18n.t('edit') + ' ' + i18n.t('registration'),
                    permissions: [permissions.ANY_ORGANISATION_CREATE],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'agreements',
                name: 'SystemDataEntryAgreements',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryAgreements.vue'
                    ),
                meta: {
                    title: i18n.t('agreements'),
                    heading: i18n.t('agreements'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'remote-applications',
                name: 'SystemDataEntryRemoteApplications',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryRemoteApplications.vue'
                    ),
                meta: {
                    title: i18n.t('remote_applications'),
                    heading: i18n.t('remote_applications'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'terms',
                name: 'SystemDataEntryTerms',
                component: () =>
                    import(
                        /* webpackChunkName: "data-entry" */
                        '@/views/SystemDataEntryTerms.vue'
                    ),
                meta: {
                    title: i18n.t('terms_and_conditions'),
                    heading: i18n.t('terms_and_conditions'),
                    permissions: [permissions.ANY_ORGANISATION_READ],
                    organisations: ['ADVISER']
                }
            }
        ]
    },
    {
        path: '/system/bulk-import',
        name: 'SystemBulkImport',
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "bulk-import" */
                    '@/views/SystemBulkImport.vue'
                ),
            sidebar: () =>
                import(
                    /* webpackChunkName: "bulk-import" */
                    '@/sidebars/SystemBulkImportSidebar.vue'
                )
        },
        meta: {
            title: i18n.t('bulk_import'),
            heading: i18n.t('bulk_import'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        },
        children: [
            {
                path: 'vct',
                name: 'SystemBulkImportVct',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportVct.vue'
                    ),
                meta: {
                    title: i18n.t('vct_and_single_company'),
                    heading: i18n.t('vct_and_single_company'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'related-organisations',
                name: 'SystemBulkImportRelatedOrganisations',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportRelatedOrganisations.vue'
                    ),
                meta: {
                    title: i18n.t('related_organisations'),
                    heading: i18n.t('related_organisations'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'funds',
                name: 'SystemBulkImportFunds',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportFunds.vue'
                    ),
                meta: {
                    title: i18n.t('funds'),
                    heading: i18n.t('funds'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'morningstar',
                name: 'SystemBulkImportMorningstar',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportMorningstar.vue'
                    ),
                meta: {
                    title: i18n.t('morningstar'),
                    heading: i18n.t('morningstar'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'cash-transactions',
                name: 'SystemBulkImportCashTransactions',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportCashTransactions.vue'
                    ),
                meta: {
                    title: i18n.t('cash') + ' ' + i18n.t('transactions'),
                    heading: i18n.t('cash') + ' ' + i18n.t('transactions'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'investment-transactions',
                name: 'SystemBulkImportInvestmentTransactions',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportInvestmentTransactions.vue'
                    ),
                meta: {
                    title: i18n.t('investment') + ' ' + i18n.t('transactions'),
                    heading: i18n.t('investment') + ' ' + i18n.t('transactions'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'advisers',
                name: 'SystemBulkImportAdvisers',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportAdvisers.vue'
                    ),
                meta: {
                    title: i18n.t('advisers'),
                    heading: i18n.t('advisers'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'investors',
                name: 'SystemBulkImportInvestors',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportInvestors.vue'
                    ),
                meta: {
                    title: i18n.t('investors'),
                    heading: i18n.t('investors'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'valuations',
                name: 'SystemBulkImportValuations',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportValuations.vue'
                    ),
                meta: {
                    title: i18n.t('valuations'),
                    heading: i18n.t('valuations'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            },
            {
                path: 'documents',
                name: 'SystemBulkImportDocuments',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-import" */
                        '@/views/SystemBulkImportDocuments.vue'
                    ),
                meta: {
                    title: i18n.t('documents'),
                    heading: i18n.t('documents'),
                    subheading: i18n.t('bulk_import'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            }
        ]
    },
    {
        path: '/system/adviser-management/:adviser_id?',
        name: 'SystemAdviserManagement',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/SystemAdviserManagement.vue'
            ),
        meta: {
            title: i18n.t('adviser_management'),
            heading: i18n.t('adviser_management'),
            permissions: [permissions.ADVISER_UPDATE],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/wysiwyg-debugger',
        name: 'SystemWysiwygDebugger',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/SystemWysiwygDebugger.vue'
            ),
        meta: {
            title: i18n.t('wysiwyg_debugger'),
            heading: i18n.t('wysiwyg_debugger'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/dynamic-form-debugger',
        name: 'SystemDynamicFormDebugger',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/SystemDynamicFormDebugger.vue'
            ),
        meta: {
            title: i18n.t('dynamic_form_debugger'),
            heading: i18n.t('dynamic_form_debugger'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/dynamic-form-builder',
        name: 'SystemDynamicFormBuilder',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/SystemDynamicFormBuilder.vue'
            ),
        meta: {
            title: i18n.t('dynamic_form_builder'),
            heading: i18n.t('dynamic_form_builder'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/error-debugger',
        name: 'SystemErrorDebugger',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/SystemErrorDebugger.vue'
            ),
        meta: {
            title: i18n.t('error_debugger'),
            heading: i18n.t('error_debugger'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/icons',
        name: 'SystemIcons',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/SystemIcons.vue'
            ),
        meta: {
            title: i18n.t('icons'),
            heading: i18n.t('icons'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/remote-application-builder',
        name: 'RemoteApplicationBuilder',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/RemoteApplicationBuilder.vue'
            ),
        meta: {
            title: i18n.t('remote_application') + ' ' + i18n.t('builder'),
            heading: i18n.t('remote_application') + ' ' + i18n.t('builder'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/bulk-share-allotment',
        name: 'BulkShareAllotment',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/BulkShareAllotment.vue'
            ),
        meta: {
            title: i18n.t('bulk_share_allotment'),
            heading: i18n.t('_page.bulk_share_allotment.heading'),
            description: i18n.t('_page.bulk_share_allotment.description'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/dri-management',
        name: 'DriManagement',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/DriManagement.vue'
            ),
        meta: {
            title: i18n.t('dri_management'),
            heading: i18n.t('_page.dri_management.heading'),
            description: i18n.t('_page.dri_management.description'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/bulk-update',
        name: 'SystemBulkUpdate',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/SystemBulkUpdate.vue'
            ),
        meta: {
            title: i18n.t('bulk_update'),
            heading: i18n.t('bulk_update'),
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        },
        children: [
            {
                path: 'offers',
                name: 'SystemBulkUpdateOffers',
                component: () =>
                    import(
                        /* webpackChunkName: "bulk-update" */
                        '@/views/SystemBulkUpdateOffers.vue'
                    ),
                meta: {
                    title: i18n.t('offers'),
                    heading: i18n.t('offers'),
                    subheading: i18n.t('bulk_update'),
                    permissions: [permissions.BULK_IMPORT],
                    organisations: ['ADVISER']
                }
            }
        ]
    },
    {
        path: '/system/proposal-calculation-debugger',
        name: 'SystemProposalCalculationDebugger',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/SystemProposalCalculationDebugger.vue'
            ),
        meta: {
            title: 'Proposal Calculation Debugger',
            heading: 'Proposal Calculation Debugger',
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    },
    {
        path: '/system/pdf-debugger',
        name: 'SystemPdfDebugger',
        component: () =>
            import(
                /* webpackChunkName: "system" */
                '@/views/SystemPdfDebugger.vue'
            ),
        meta: {
            title: 'PDF Debugger',
            heading: 'PDF Debugger',
            permissions: [permissions.BULK_IMPORT],
            organisations: ['ADVISER']
        }
    }
];
