import i18n from '@/lib/i18n';
import Auth from '@/models/Auth';

import LibrarySidebar from '@/sidebars/LibrarySidebar.vue';

export default [
    {
        path: '/library',
        name: 'LibraryIndex',
        components: {
            default: () =>
                import(
                    /* webpackChunkName: "library" */
                    '@/views/LibraryIndex.vue'
                ),
            sidebar: {
                render(h) {
                    if (Auth().is_investor) {
                        return null;
                    } else {
                        return h(LibrarySidebar);
                    }
                }
            }
        },
        children: [
            {
                path: ':library?',
                name: 'Library',
                component: () =>
                    import(
                        /* webpackChunkName: "library" */
                        '@/views/Library.vue'
                    ),
                meta: {
                    title: i18n.t('library'),
                    heading: i18n.t('library'),
                    description: i18n.t('upload_and_manage_documents'),
                    organisations: ['ADVISER', 'INVESTOR']
                }
            }
        ]
    }
];
